//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      center: [114.023254, 22.617011], // 总部地址：深圳市龙华新区创业花园淘景大厦1901
    };
  },
  mounted() {
    this.initMap();
  },
  created() {},
  methods: {
    initMap() {
      let this_ = this;
      var map = new AMap.Map("container", {
        viewMode: "2D", // 默认使用 2D 模式，如果希望使用带有俯仰角的 3D 模式，请设置 viewMode: '3D',
        zoom: 18, //初始化地图层级
        center: this_.center, //初始化地图中心点
      });
      // 定位
      //   AMap.plugin("AMap.Geolocation", function () {
      //     var geolocation = new AMap.Geolocation({
      //       enableHighAccuracy: true, //是否使用高精度定位，默认:true
      //       timeout: 10000, //超过10秒后停止定位，默认：5s
      //       position: "RB", //定位按钮的停靠位置
      //       buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
      //       zoomToAccuracy: true, //定位成功后是否自动调整地图视野到定位点
      //     });
      //     map.addControl(geolocation);
      //     geolocation.getCurrentPosition(function (status, result) {
      //       if (status == "complete") {
      //         onComplete(result);
      //       } else {
      //         onError(result);
      //       }
      //     });
      //   });
      //   //解析定位结果
      //   function onComplete(data) {
      //     console.log(data);
      //     this_.center[0] = data.position.lng;
      //     this_.center[1] = data.position.lat;
      //   }
      //   //解析定位错误信息
      //   function onError(data) {
      //     console.log(data);
      //   }
      //   AMap.plugin("AMap.Geocoder", function () {
      //     var geocoder = new AMap.Geocoder({
      //       city: "010", //城市设为北京，默认：“全国”
      //       radius: 1000, //范围，默认：500
      //     });

      //     var lnglat = [114.023254, 22.617011];
      //     marker.setPosition(lnglat);
      //     geocoder.getAddress(lnglat, function (status, result) {
      //       if (status === "complete" && result.info === "OK") {
      //         console.log(result);
      //         // var address = result.regeocode.formattedAddress;
      //       } else {
      //         console.log("根据经纬度查询地址失败");
      //       }
      //     });
      //   });
      // 点标记
      var marker = new AMap.Marker({
        position: new AMap.LngLat(114.023254, 22.617011), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      });
      // 将创建的点标记添加到已有的地图实例：
      map.add(marker);
    },
  },
};
